@use 'vars' as *;

body {
    font-family: $font-primary;
    font-size: 16px;
    color: #707070;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#bbbbbb;
    font-size: clamp(20px, 5vw, 40px);
}

.button, div[data-edit] a.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: $yellow;
    background-color: $green;
    border: 1px solid $green;
    box-sizing: border-box;
    transition: all 0.3s ease;
    &:hover {
        color: $green;
        background-color: transparent;
    }
}


img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

img.full-width {
    width: 100%!important;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}

.text-condense{
    font-family: $font-secondary;
}
.text-yellow{
    color: $yellow;
}
.text-green{
    color: $green;
}
.text-white{
    color: $white;
}
.text-light{
    font-weight: 300;
}
.text-regular{
    font-weight: 400;
}
.two-col{
    display:grid;
    grid-gap:1rem;
    grid-template-columns: repeat(2, 1fr);
    margin:0;
    padding:0;
    list-style: none;
    @media screen and (max-width:576px) {
        grid-template-columns: 1fr; 
    }
}
.img-circle{
    border-radius: 50%;
    overflow: hidden;
}
.view-more{
    color: $green;
    text-decoration: none;
    font-size: clamp(17px, 4vw, 20px);
    &:hover{
        text-shadow: 0 0 1px #000;
    }
}
.no-break{
    white-space:nowrap;
}
[data-edit=true] h2 {
    font-size: 1.17em;
}